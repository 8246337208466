import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import providerReducer from './slices/provider';
import categoryReducer from './slices/category';
import marketplaceReducer from './slices/marketplace';
import gameReducer from './slices/game';
import betReducer from './slices/bets';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  provider: providerReducer,
  category: categoryReducer,
  marketplace: marketplaceReducer,
  game: gameReducer,
  bet: betReducer
});

export { rootPersistConfig, rootReducer };
