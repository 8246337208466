import { createSlice } from '@reduxjs/toolkit';
import { BetData, BetReturnData } from 'src/@types/bets';
import { BetFilterTypes } from 'src/@types/filters';
import { post } from 'src/utils/api';
import { GET_BETS } from 'src/utils/constants/routeConstants';
import { dispatch } from '../store';

type BetState = {
  isLoading: boolean;
  error: Error | string | null;
  bets: BetReturnData;
};

const initialState: BetState = {
  isLoading: false,
  error: null,
  bets: {} as BetReturnData,
};

const slice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBetsSuccess(state, action) {
      state.isLoading = false;
      state.bets = action.payload;
    },
  },
});

export default slice.reducer;

export function getBets(page: number, rowsPerPage: number, filters: BetFilterTypes) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        userId: filters.userId,
        entityId: filters.entityId,
        username: filters.name,
        roundId: filters.roundId,
        providerName: filters.provider,
        payInAmount: parseInt(filters.payInAmount),
        payOutAmount: parseInt(filters.payOutAmount),
        percentageGain: parseInt(filters.percentageGain),
        gameId: filters.gameId,
        betClosed: filters.betClosed === '' ? null : filters.betClosed,
        dateFrom: parseInt(filters.dateFrom),
        dateTo: parseInt(filters.dateTo),
      };
      const response = (await post(`${GET_BETS}`, data as BetData)) as BetReturnData;
      console.log(response.bets[response.bets.length - 1]);
      dispatch(slice.actions.getBetsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
