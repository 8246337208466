import { createSlice } from '@reduxjs/toolkit';
import { ProviderReturnData } from 'src/@types/provider';
import { post } from 'src/utils/api';
import { GET_PROVIDERS } from 'src/utils/constants/routeConstants';
import { dispatch } from '../store';

type ProviderState = {
  isLoading: boolean;
  error: Error | string | null;
  providerData: ProviderReturnData | null;
};

const initialState: ProviderState = {
  isLoading: false,
  error: null,
  providerData: null,
};

const slice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProvidersSuccess(state, action) {
      state.isLoading = false;
      state.providerData = action.payload;
    },
  },
});

export default slice.reducer;

export function getProviders(providerName?: string, page?: number, rowsPerPage?: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: providerName || '',
        limit: 100,
        offset: 0
      }
      const response = (await post(`${GET_PROVIDERS}`, data)) as ProviderReturnData;
      dispatch(slice.actions.getProvidersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
